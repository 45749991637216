<template>
  <pre v-html="jsonToPretty" class="prettier"></pre>
</template>

<script>
export default {
  name: "PrettyPrintJson",
  data: () => ({
    jsonToPretty: "",
  }),
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * @function syntaxisHighlight Función que se utiliza para clasificar en key, string, number, boolean y null cada palabra contenida en el Json.
     * @param {string} json Json stringify del json que se quiere visualizar mejor.
     * @return Retorna código HTML que representa al Json con sus debidas clases separadas en span para mostrar distintos colores.
     */
    syntaxisHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        (match) => {
          var cls = "number-prettier";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key-prettier";
            } else {
              cls = "string-prettier";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean-prettier";
          } else if (/null/.test(match)) {
            cls = "null-prettier";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    },
  },
  mounted() {
    this.jsonToPretty = this.syntaxisHighlight(
      JSON.stringify(this.message, null, 4)
    );
  },
};
</script>

<style lang="sass">
pre
  outline: 1px solid #ccc
  padding: 5px
  margin: 5px
  background-color: #193549
  color: #9effff

.string-prettier
  color: #a5ff90

.number-prettier
  color: #ff627b

.boolean-prettier
  color: #ff627b

.null-prettier
  color: #ff627b

.key-prettier
  color: #9effff
</style>