<template>
  <div>
    <tgl-card-header>
      <h1>{{ $t("device.streamingTitle") }}</h1>
    </tgl-card-header>
    <tgl-card-content>
      <v-data-table
        :headers="headers"
        :items="events"
        :items-per-page="5"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="shrink">Contraer todo</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.timestamp="{ item }">
          {{ formatDate(item.timestamp) }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="p-0">
            <pretty-print-json :message="item.message"></pretty-print-json>
          </td>
        </template>
      </v-data-table>
    </tgl-card-content>
  </div>
</template>

<script>
import TglCardContent from "@/components/Cards/TglCardContent.vue";
import TglCardHeader from "@/components/Cards/TglCardHeader.vue";
import PrettyPrintJson from "@/components/UI/PrettyPrintJson";

import { mapGetters } from "vuex";
export default {
  components: {
    TglCardContent,
    TglCardHeader,
    PrettyPrintJson,
  },
  data: () => ({
    headers: [
      {
        text: "Tipo de evento",
        align: "start",
        sortable: false,
        value: "eventType",
      },
      { text: "Timestamp", value: "timestamp", sortable: false },
      { text: "", value: "data-table-expand" },
      //value = nombre del atributo dentro del array de items
    ],
    events: [],
    expanded: [],
    device: {},
  }),
  computed: {
    ...mapGetters("user", ["user"]),
  },
  watch: {
    user: {
      handler() {
        if (this.user.organization_id) this.getDevice();
      },
      deep: true,
    },
  },
  methods: {
    subscribe(deviceIdentifier) {
      this.$tgcWebsocket.subscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.up`,
        this.onDeviceEvent
      );
      this.$tgcWebsocket.subscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.wot`,
        this.onDeviceEvent
      );
      this.$tgcWebsocket.subscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.custom`,
        this.onDeviceEvent
      );
      this.$tgcWebsocket.subscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.downtime`,
        this.onDeviceEvent
      );
    },
    unsubscribe(deviceIdentifier) {
      this.$tgcWebsocket.unsubscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.up`,
        this.onDeviceEvent
      );
      this.$tgcWebsocket.unsubscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.wot`,
        this.onDeviceEvent
      );
      this.$tgcWebsocket.unsubscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.custom`,
        this.onDeviceEvent
      );
      this.$tgcWebsocket.unsubscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.downtime`,
        this.onDeviceEvent
      );
    },
    /**
     * @function onDeviceEvent Función que agrega los eventos al atributo events y les asigna el nombre del tipo.
     * @param {object} message Objeto que contiene la respuesta del servidor websocket con la información del evento que se está stremeando.
     */
    onDeviceEvent(message) {
      let eventType;
      let key = this.events.length + 1;
      if (message.topic.includes("event.up")) eventType = "UP LINK";
      if (message.topic.includes("event.downtime")) eventType = "DOWNTIME";
      if (message.topic.includes("event.wot")) eventType = "WINDOW OF TIME";
      if (message.topic.includes("event.custom")) eventType = "CUSTOM";
      if (message.topic.includes("event.mc")) eventType = "MATH CONDITION";
      this.events.unshift({
        message,
        timestamp: new Date(),
        eventType,
        id: key,
      });
    },
    /**
     * @function formatDate Función que formatea la salida de la fecha y hora a partir de un timestamp.
     * @param {date} timestamp Fecha y hora en que se añade el mensaje a events.
     */
    formatDate(timestamp) {
      let date = timestamp.toLocaleDateString("es-es");
      let time = timestamp.toLocaleTimeString("es-es");

      return `${date} -  ${time}`;
    },
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
          this.subscribe(data.identifier);
        })
        .catch((error) => {
          console.err(error);
        });
    },
    shrink() {
      this.expanded = [];
    },
  },
  mounted() {
    if (this.user.organization_id) this.getDevice();
  },
  beforeDestroy() {
    this.unsubscribe(this.device.identifier);
  },
};
</script>

<style>
</style>